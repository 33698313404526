@import-normalize;

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --hot-color: #e00;
  --light-blue: #c8e7ff;
  --text-color: #333;
  --highlight: #d9edfd;
}

body {
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  margin: 8px;
  line-height: 1.3em;
}

ul {
  padding: 0 0 0 2em;
}

h1 {
  margin: 0 0 0.67em;
  font: 30px Arial, Helvetica, sans-serif;
}

h1 em {
  color: blue;
}
h2 {
  margin: 0.83em 0;
  font: 22px Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: var(--hot-color);
}

kbd,
samp {
  font: normal 14px "Lucida Console", Monaco, monospace;
}

.callout {
  margin: 1em 40px;
  padding: 20px;
  background: var(--light-blue);
}

.callout kbd {
  background: #aad5f7;
}

.help {
  border-left: 10px solid var(--light-blue);
  padding-left: 20px;
  margin: 0 40px;
}

.info li {
  line-height: 1.4em;
  margin-top: 0.2em;
}

.info h2 {
  color: var(--text-color);
  margin-top: 0;
}

.info-icon {
  width: 20px;
  vertical-align: text-bottom;
}

.info kbd {
  padding-left: 0.5em;
  padding-right: 0.5em;
  background: #eee;
}

.info :last-child {
  margin-bottom: 0;
}

.tool {
  margin-top: 1em;
}

.tool form {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-bottom: 1em;
}

.tool input,
.tool button {
  height: 40px;
  padding: 2px 6px;
  font-size: 1em;
  flex: none;
}

.tool input {
  font: normal 20px Arial, Helvetica, sans-serif;
  flex: 1;
  border: 1px solid #a4a4a4;
  margin-right: 0;
}

.tool .btn {
  margin: 0;
  background: #efefef;
  border: 1px solid #a4a4a4;
}

.tool .btn-clear {
  background: none;
  border: none;
  color: #00e;
  margin-left: 20px;
}

.preview {
  color: var(--hot-color);
  margin-top: 6px;
  font-size: 20px;
}

.hidden {
  display: none;
}

.results {
  font-size: 18px;
  display: table;
}

.results .entry {
  display: table-row;
}

.results .dfn {
  display: table-cell;
  font-style: normal;
  padding-left: 1em;
  overflow: hidden;
  white-space: nowrap;
}

.results .entry.good {
  background: var(--highlight);
  flex: 0 1 auto;
}

.results .word {
  white-space: nowrap;
  text-decoration: none;
  font-family: "Lucida Console", Monaco, monospace;
  display: table-cell;
}

.thesaurus-preview {
  font-size: 18px;
  min-height: 600px;
}

.thesaurus-preview li {
  margin-bottom: 0.5em;
  line-height: 1.3em;
}

section {
  margin-bottom: 40px;
  width: 100%;
  max-width: 1000px;
}

.overlay {
  background-color: #000000aa;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
}

.modal-close {
  background: none;
  border: none;
  position: fixed;
  top: 16px;
  right: 16px;
}

.dictionary-iframe {
  width: 100%;
  height: 100%;
}

.iframe-loading {
  opacity: 0;
}

#app.uninit {
  display: block;
  /* Rough estimate: the JS turns off the explicit height
   * once it's initialized the app.
   */
  height: 1200px;
}

/* vim:sw=2 et
 */
